:root {
    --primary-color: #f2bd0f;
    --secondary-color: #6c757d;
    --bg-color: #f8f9fa;
  }
  
  .checkout-container {
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    
  }
  .address-card:hover{
    border: 1px solid lightgray;
  }
  .checkout-form {
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  
  .secure-text {
    font-size: 14px;
    color: #28a745;
  }
  
  .button-payment {
    padding: 12px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
  }
  
  .button-payment:disabled {
    background-color: var(--secondary-color);
    cursor: not-allowed;
  }
  
  .payment-result {
    text-align: center;
    padding: 30px;
  }
  
  .success-page h1 {
    color: green;
  }
  
  .failure-page h1 {
    color: red;
  }
  