/* Footer Container */
.footer-container {
    background-color: black;
    color: white;
    padding: 2rem 0;
    width: 100%;
  }
  
  /* Footer Content */
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }
  
  /* Each Footer Section */
  .footer-section {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .footer-section p {
    font-size: 0.9rem;
    margin: 0.3rem 0;
    color: #ccc;
    cursor: pointer;
  }
  
  .footer-section p:hover {
    color: white;
  }
  
  /* Subscribe Box */
  .subscribe-box {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    max-width: 250px;
  }
  
  .subscribe-box input {
    flex: 1;
    padding: 0.5rem;
    border: none;
    outline: none;
  }
  
  .subscribe-box button {
    background-color: black;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  /* Download Section */
  .download-box {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .qr-code {
    width: 50px;
    height: 50px;
  }
  
  .app-links img {
    width: 120px;
    display: block;
  }
  
  /* Social Icons */
  .social-icons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .social-icons svg {
    font-size: 1.2rem;
    cursor: pointer;
    color: #ccc;
  }
  
  .social-icons svg:hover {
    color: white;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    text-align: center;
    padding: 1rem 0;
    border-top: 1px solid #333;
    margin-top: 2rem;
    font-size: 0.85rem;
    color: #ccc;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .download-box {
      flex-direction: column;
      align-items: center;
    }
  
    .app-links img {
      margin-top: 0.5rem;
    }
  
    .social-icons {
      justify-content: center;
    }
  }
  