:root {
    --primary-color: #f2bd0f;
    --secondary-color: #6c757d;
    --bg-color: #f8f9fa;
}
.collection-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .card:hover .card-image img {
    transform: scale(1.05);
  }
  
  .card-content {
    padding: 1.5rem;
  }
  
  .card-content h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
  }
  
  .card-content p {
    margin: 0 0 1rem 0;
    color: #666;
    line-height: 1.5;
  }
  
  .view-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-button:hover {
    background-color: var(--primary-color);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1.5rem;
    }
    
    .collection-container {
      padding: 1rem;
    }
    
    .card-content {
      padding: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .card-grid {
      grid-template-columns: 1fr;
    }
  }