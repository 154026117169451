.profile-management {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  /* Breadcrumb */
  .breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 0.875rem;
  }
  
  .breadcrumb-links {
    color: #666;
  }
  
  .separator {
    margin: 0 0.5rem;
  }
  
  .welcome-text {
    font-size: 0.875rem;
  }
  
  .user-name {
    color: #DB4444;
  }
  
  /* Main Container */
  .main-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 2rem;
  }
  
  @media (max-width: 768px) {
    .main-container {
      grid-template-columns: 1fr;
    }
  }
  
  /* Sidebar */
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .menu-section {
    margin-bottom: 1.5rem;
  }
  
  .menu-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .menu-items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .menu-item {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    color: #666;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .menu-item:hover {
    color: #333;
  }
  
  .menu-item.active {
    color: #DB4444;
  }
  
  /* Content Container */
  .content-container {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
  }
  
  /* Profile Content */
  .profile-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .content-title {
    font-size: 1.25rem;
    font-weight: 500;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  @media (max-width: 640px) {
    .form-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-size: 0.875rem;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    background-color: #f9fafb;
  }
  
  /* Password Section */
  .password-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .section-title {
    font-size: 1.125rem;
    font-weight: 500;
  }
  
  /* Form Actions */
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .btn {
    padding: 0.5rem 1.5rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .btn-cancel {
    border: 1px solid #ddd;
    background-color: white;
  }
  
  .btn-cancel:hover {
    background-color: #f9fafb;
  }
  
  .btn-save {
    background-color: black;
    color: white;
    border: none;
  }
  
  .btn-save:hover {
    background-color: #333;
  }
  
  /* Placeholder Content */
  .placeholder-content {
    padding: 1rem;
  }