.address-book {
    max-width: 1024px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .header h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .add-button {
    background-color: #ff3f6c;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .add-button:hover {
    background-color: #ff2957;
  }
  
  .modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 36rem;
  }
  
  .modal h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  
  .form-group input {
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    width: 100%;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #ff3f6c;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .checkbox-group input {
    margin-right: 0.5rem;
  }
  
  .checkbox-group label {
    font-size: 0.875rem;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .cancel-button {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    background-color: white;
    cursor: pointer;
  }
  
  .cancel-button:hover {
    background-color: #f9fafb;
  }
  
  .submit-button {
    padding: 0.5rem 1rem;
    background-color: #ff3f6c;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #ff2957;
  }
  
  .address-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .address-card {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    transition: border-color 0.2s;
  }
  
  .address-card:hover {
    border-color: #ff3f6c;
  }
  
  .address-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .address-details h3 {
    font-weight: bold;
    font-size: 1.125rem;
  }
  
  .address-details .phone {
    color: #6b7280;
    margin-top: 0.25rem;
  }
  
  .address-details .address {
    margin-top: 0.5rem;
  }
  
  .address-actions {
    display: flex;
    gap: 1rem;
  }
  
  .edit-button {
    color: #ff3f6c;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  .edit-button:hover {
    color: #ff2957;
  }
  
  .delete-button {
    color: #6b7280;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  .delete-button:hover {
    color: #4b5563;
  }
  
  @media (max-width: 640px) {
    .form-grid {
      grid-template-columns: 1fr;
    }
    
    .address-content {
      flex-direction: column;
      gap: 1rem;
    }
    
    .address-actions {
      width: 100%;
      justify-content: flex-end;
    }
  }