/* Global Styles */
:root {
	--primary: #f2bd0f;
	--secondary: #6c757d;
  }
  
  * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  }
  
  html, body {
	width: 100%;
	overflow-x: hidden;
  }
  
  /* Header Container - Make sticky */
  .header-container {
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 1000;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Announcement Bar */
  .announcement-bar {
	width: 100%;
	background-color: var(--primary);
	color: white;
	padding: 0.5rem 1rem;
  }
  
  /* Announcement Content */
  .announcement-content {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.875rem;
	flex-wrap: wrap;
  }
  
  /* Shop Now Link */
  .shop-now {
	font-weight: bold;
	margin-left: 0.5rem;
	cursor: pointer;
  }
  
  /* Language Selector */
  .language-selector {
	background-color: transparent;
	color: white;
	border: none;
	cursor: pointer;
	padding: 0.25rem;
  }
  
  .language-selector option {
	background-color: white;
	color: black;
  }
  
  /* Main Header */
  .main-header {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1rem;
	background-color: white;
  }
  
  /* Header Content */
  .header-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
  }
  
  /* Logo */
  .logo {
	font-size: 1.5rem;
	font-weight: bold;
  }
  
  .logo img {
	max-height: 40px;
  }
  
  /* Navigation */
  .main-navigation {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
  }
  
  .nav-item {
	font-size: 1rem;
	color: black;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0.5rem;
	transition: color 0.3s ease;
  }
  
  .nav-item:hover {
	color: var(--primary);
  }
  
  .nav-item.active {
	color: var(--primary);
	font-weight: bold;
	border-bottom: 2px solid var(--primary);
  }
  
  /* Search and Icons */
  .search-and-icons {
	display: flex;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
  }
  
  .search-box {
	position: relative;
  }
  
  .search-input {
	width: 100%;
	max-width: 16rem;
	padding: 0.5rem 2.5rem 0.5rem 1rem;
	border: 1px solid #e5e7eb;
	border-radius: 4px;
	transition: border-color 0.3s ease;
  }
  
  .search-input:focus {
	outline: none;
	border-color: var(--primary);
  }
  
  .search-icon {
	position: absolute;
	right: 0.75rem;
	top: 50%;
	transform: translateY(-50%);
	color: gray;
  }
  
  .icon-btn {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0.5rem;
	transition: color 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .icon-btn:hover {
	color: var(--primary);
  }
  
  /* Apply Responsiveness for Large Screens */
  @media (min-width: 1400px) {
	.announcement-content,
	.main-header {
	  max-width: 1400px;
	  margin: 0 auto;
	}
  
	.main-navigation {
	  gap: 3rem;
	}
  
	.search-input {
	  width: 20rem;
	}
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
	.header-container {
	  position: sticky;
	  top: 0;
	}
  
	.header-content {
	  flex-direction: column;
	  align-items: center;
	  text-align: center;
	  gap: 1rem;
	  padding: 0.5rem;
	}
  
	.main-navigation {
	  flex-direction: column;
	  gap: 0.5rem;
	  align-items: center;
	  width: 100%;
	}
  
	.search-and-icons {
	  flex-wrap: wrap;
	  justify-content: center;
	  width: 100%;
	  gap: 0.5rem;
	}
  
	.search-box {
	  width: 100%;
	}
  
	.search-input {
	  max-width: 100%;
	}
  
	.announcement-content {
	  justify-content: center;
	  text-align: center;
	}
  
	.language-selector {
	  margin-top: 0.5rem;
	}
  }