.referral-container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Referral Code Section */
  .referral-code-section {
    background: linear-gradient(135deg, #ff3f6c 0%, #ff2957 100%);
    border-radius: 12px;
    padding: 30px;
    color: white;
    margin-bottom: 30px;
  }
  
  .referral-code-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .referral-info h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .referral-description {
    font-size: 16px;
    opacity: 0.9;
  }
  
  .code-display {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .code-box {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .code {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .copy-button {
    background: white;
    color: #ff3f6c;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .copy-button:hover {
    transform: translateY(-1px);
  }
  
  .share-buttons {
    display: flex;
    gap: 10px;
  }
  
  .share-button {
    flex: 1;
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    background: transparent;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .share-button:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  /* Earnings Section */
  .earnings-section {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  .earnings-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .total-earnings h2 {
    font-size: 18px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .total-earnings .amount {
    font-size: 32px;
    font-weight: 600;
    color: #ff3f6c;
  }
  
  .earnings-stats {
    display: flex;
    gap: 20px;
  }
  
  .stat-box {
    text-align: center;
    padding: 15px;
    background: #f8f8f8;
    border-radius: 8px;
    min-width: 120px;
  }
  
  .stat-value {
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 4px;
  }
  
  .stat-label {
    font-size: 14px;
    color: #666;
  }
  
  .earnings-history h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .history-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #f8f8f8;
    border-radius: 8px;
  }
  
  .history-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .referred-user {
    font-weight: 500;
    color: #333;
  }
  
  .earning-date {
    font-size: 14px;
    color: #666;
  }
  
  .history-amount {
    text-align: right;
  }
  
  .status {
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .status.credited {
    color: #22c55e;
  }
  
  .status.pending {
    color: #f59e0b;
  }
  
  .amount {
    font-weight: 500;
    color: #333;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .earnings-header {
      flex-direction: column;
      gap: 20px;
    }
  
    .earnings-stats {
      width: 100%;
      justify-content: space-between;
    }
  
    .stat-box {
      flex: 1;
    }
  
    .history-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .history-amount {
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .share-buttons {
      flex-direction: column;
    }
  
    .code-box {
      flex-direction: column;
      gap: 10px;
      text-align: center;
    }
  
    .earnings-stats {
      flex-direction: column;
    }
  
    .stat-box {
      width: 100%;
    }
  }