.auth-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
  }
  
  .auth-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
  }
  
  /* Image side */
  .auth-image {
    background-color: #f0f9ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  .signup-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  /* Form side */
  .auth-form-container {
    padding: 2rem;
    display: flex;
    align-items: center;
  }
  
  .auth-form-content {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .auth-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #000;
  }
  
  .auth-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-field {
    width: 100%;
  }
  
  .form-field input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
  }
  
  .form-field input::placeholder {
    color: #9ca3af;
  }
  
  .form-field input:focus {
    outline: none;
    border-color: #000;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
  }
  
  .submit-button:hover {
    background-color: #1a1a1a;
  }
  
  .auth-footer {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 0.875rem;
    color: #666;
  }
  
  .login-link {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
    margin-left: 0.25rem;
  }
  
  .login-link:hover {
    color: #1a1a1a;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .auth-content {
      grid-template-columns: 1fr;
    }
  
    .auth-image {
      display: none;
    }
  
    .auth-form-container {
      padding: 1.5rem;
    }
  
    .auth-title {
      font-size: 1.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .auth-container {
      padding: 1rem;
    }
  
    .auth-form-container {
      padding: 1rem;
    }
  
    .auth-title {
      font-size: 1.5rem;
    }
  }