.subscription-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .subscription-header {
    text-align: center;
    margin-bottom: 48px;
  }
  
  .subscription-header h1 {
    font-size: 32px;
    font-weight: 700;
    color: #1a202c;
    margin-bottom: 12px;
  }
  
  .subscription-header p {
    font-size: 18px;
    color: #718096;
  }
  
  .plans-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    justify-content: center;
  }
  
  .plan-card {
    position: relative;
    background: white;
    border-radius: 16px;
    padding: 32px;
    transition: all 0.3s ease;
    border: 2px solid #e2e8f0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .plan-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px var(--plan-shadow);
  }
  
  .plan-card.selected {
    border-color: var(--plan-color);
    box-shadow: 0 8px 16px var(--plan-shadow);
  }
  
  .plan-card.recommended {
    border-color: var(--plan-color);
    transform: scale(1.05);
  }
  
  .recommended-badge {
    position: absolute;
    top: -12px;
    right: 24px;
    background: var(--plan-color);
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .plan-header {
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .plan-name {
    color: var(--plan-color);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  
  .plan-price {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 4px;
  }
  
  .currency {
    font-size: 24px;
    font-weight: 600;
    color: #2d3748;
  }
  
  .amount {
    font-size: 48px;
    font-weight: 700;
    color: #2d3748;
    line-height: 1;
  }
  
  .period {
    font-size: 16px;
    color: #718096;
  }
  
  .free-tag {
    font-size: 16px;
    font-weight: 600;
    color: #48bb78;
    margin-left: 8px;
    padding: 2px 8px;
    background-color: #f0fff4;
    border-radius: 4px;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    margin: 0 0 32px 0;
  }
  
  .plan-features li {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 16px;
    color: #4a5568;
    line-height: 1.5;
  }
  
  .feature-icon {
    width: 20px;
    height: 20px;
    color: var(--plan-color);
    flex-shrink: 0;
    margin-top: 2px;
  }
  
  .subscribe-button {
    width: 100%;
    padding: 12px 24px;
    border-radius: 8px;
    border: 2px solid var(--plan-color);
    background: transparent;
    color: var(--plan-color);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .subscribe-button:hover {
    background: var(--plan-color);
    color: white;
  }
  
  .subscribe-button.subscribed {
    background: var(--plan-color);
    color: white;
    cursor: default;
  }
  
  @media (max-width: 768px) {
    .plans-container {
      grid-template-columns: 1fr;
      max-width: 400px;
      margin: 0 auto;
    }
  
    .plan-card.recommended {
      transform: none;
      order: -1;
    }
  
    .plan-card {
      margin-bottom: 20px;
    }
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
  }
  
  .plan-card.selected {
    animation: pulse 2s infinite;
  }