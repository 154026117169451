:root {
  --primary: #f2bd0f;
  --secondary: #6c757d;
}

.product-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem;
}

.loading-state {
  text-align: center;
  padding: 2rem;
  font-size: 1.125rem;
  color: #666;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1.5rem;
}

.separator {
  margin: 0 0.5rem;
}

/* Product Grid */
.product-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

/* Image Gallery */
.image-gallery {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}

.main-image {
  aspect-ratio: 1;
  max-height: 500px; /* Adjust this value as needed */
  /* background-color: #f3f4f6; */
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}


.product-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumbnails {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
}

.thumbnail-btn {
  min-width: 80px;
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 2px solid transparent;
  transition: all 0.2s;
  cursor: pointer;
}

.thumbnail-btn.active {
  border-color: var(--primary);
}

.thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Product Info */
.product-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.product-header {
  margin-bottom: 1.5rem;
}

.product-title-d {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.product-description {
  color: #4b5563;
  margin-bottom: 1rem;
}

.rating-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.stars {
  display: flex;
}

.star {
  width: 1.25rem;
  height: 1.25rem;
}

.star.filled {
  color: #fbbf24;
  fill: currentColor;
}

.star.empty {
  color: #d1d5db;
  fill: currentColor;
}

.review-count {
  font-size: 0.875rem;
  color: #6b7280;
}

.price-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.original-price {
  font-size: 1.125rem;
  color: #6b7280;
  text-decoration: line-through;
}

.discount-d {
  color: #059669;
  font-weight: 500;
  background-color: #ecfdf5;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.selling-price {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
}

.discount-amount {
  font-size: 0.875rem;
  color: #059669;
  font-weight: 500;
}

.variant-title {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.color-options {
  display: flex;
  gap: 1rem;
}

.color-btn {
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  border: 2px solid #e5e7eb;
  transition: all 0.2s;
  cursor: pointer;
}

.color-btn.active {
  border-color: var(--secondary);
}

.size-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.size-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  transition: all 0.2s;
  cursor: pointer;
}

.size-btn:hover {
  border-color: #d1d5db;
}

.size-btn.active {
  background-color: var(--primary);
  color: white;
}

/* Purchase Actions */
.purchase-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.quantity-selector {
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.quantity-btn {
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.quantity-btn:hover {
  background-color: #f9fafb;
}

.quantity-display {
  padding: 0.5rem 1rem;
  border-left: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
  min-width: 3rem;
  text-align: center;
}

.buy-now-btn {
  flex: 1;
  padding: 0.75rem 1.5rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.buy-now-btn:hover {
  background-color: var(--primary);
}

.wishlist-btn {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.wishlist-btn:hover {
  background-color: #f9fafb;
}

/* Specifications */
.specifications {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.specs-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.spec-group {
  margin-bottom: 1.5rem;
}

.spec-group:last-child {
  margin-bottom: 0;
}

.spec-group-title {
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.spec-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.spec-item {
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f3f4f6;
}

.spec-item:last-child {
  border-bottom: none;
}

.spec-key {
  width: 40%;
  color: #6b7280;
  font-size: 0.875rem;
}

.spec-value {
  width: 60%;
  font-size: 0.875rem;
}

/* Delivery Info */
.delivery-info {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.delivery-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-bottom: 1rem;
}

.delivery-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.delivery-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #4b5563;
  flex-shrink: 0;
}

.delivery-text {
  flex: 1;
}

.delivery-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.delivery-subtitle {
  font-size: 0.875rem;
  color: #6b7280;
}

.details-link {
  background: none;
  border: none;
  color: #3b82f6;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-left: 0.25rem;
}

/* Responsive Design */
@media (min-width: 768px) {
  .product-container {
    padding: 2rem;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .image-gallery {
    flex-direction: row;
  }

  .thumbnails {
    flex-direction: column;
    width: 6rem;
  }

  .main-image {
    flex: 1;
  }

  .product-title-d {
    font-size: 1.5rem;
  }

}

/* Loading State Animation */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.loading-state {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.product-details {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.product-images {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.thumbnail-list {
  display: flex;
  flex-direction: column;
}

.thumbnail-list img {
  width: 60px;
  height: 60px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}

.thumbnail-list img.active,
.thumbnail-list img:hover {
  border: 2px solid #000;
}

.main-image img {
  width: 100%;
  border-radius: 10px;
}

.product-info {
  padding: 20px;
}

h2 {
  font-size: 24px;
}

.rating {
  color: #666;
}

.in-stock {
  color: green;
}

.price {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0;
}

.description {
  font-size: 14px;
  color: #555;
}

.options {
  margin: 15px 0;
}

.colors {
  display: flex;
  align-items: center;
}

.color-option {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
  border: 2px solid transparent;
}

.color-option.selected {
  border: 2px solid black;
}

.sizes {
  margin-top: 10px;
}

.size-btn {
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.quantity-selector {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.quantity-selector button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.quantity-selector .buy-now {
  background: black;
  color: white;
  padding: 8px 20px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
}

.delivery-info {
  margin-top: 20px;
}

.delivery-option {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.specifications {
  border-top: 1px solid #e5e7eb;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.specifications h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #111827;
}

.spec-category {
  margin-bottom: 2rem; /* Added spacing between categories */
}

.spec-category h4 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #374151;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 0.5rem;
}

.spec-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Ensures two equal columns */
  gap: 1rem; /* Adds spacing between items */
  align-items: center;
}

.spec-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: #f9fafb; /* Light background for contrast */
  border-radius: 6px; /* Rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.spec-key {
  font-size: 0.875rem;
  font-weight: 600;
  color: #6b7280;
  text-transform: capitalize;
  flex: 1;
}

.spec-value {
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
  flex: 1;
  text-align: right; /* Align values to the right */
}
.add-to-cart-btn,
.add-to-wishlist-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.add-to-cart-btn {
  background-color: var(--secondary);
  color: white;
  border: none;
}

.add-to-cart-btn:hover {
  background-color: var(--primary);
}

.add-to-wishlist-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  background-color: white;
  color: #1f2937;
  font-weight: bold;
}

.add-to-wishlist-btn:hover {
  background-color: #f9fafb;
}
