.cart-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: sans-serif;
  }
  
  .cart-header {
    margin-bottom: 30px;
  }
  
  .home-link {
    color: #000;
    text-decoration: none;
  }
  
  .separator {
    margin: 0 10px;
    color: #666;
  }
  
  .current-page {
    color: #666;
  }
  
  .cart-content {
    display: flex;
    gap: 30px;
  }
  
  .cart-items-section {
    flex: 1;
  }
  
  .cart-table-header {
    display: grid;
    grid-template-columns: 2fr 1fr 0.8fr 0.8fr 1fr 1fr 0.5fr;
    padding: 15px;
    background-color: #f8f9fa;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .cart-item-cart {
    display: grid;
    grid-template-columns: 2fr 1fr 0.8fr 0.8fr 1fr 1fr 0.5fr;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
  }
  
  .item-product {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .item-product img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .product-name {
    font-weight: 500;
  }
  
  .product-sku, .product-brand {
    font-size: 0.9em;
    color: #666;
  }
  
  .price-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .selling-price {
    font-weight: 500;
    color: #000;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #666;
    font-size: 0.9em;
  }
  
  .discount {
    color: #ff0000;
    font-size: 0.9em;
  }
  
  .color-swatch {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
  }
  
  .delete-button {
    background: none;
    border: none;
    color: #ff0000;
    font-size: 24px;
    cursor: pointer;
    padding: 0 10px;
  }
  
  .delete-button:hover {
    color: #cc0000;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    width: fit-content;
  }
  
  .quantity-control button {
    padding: 5px 10px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .quantity-control input {
    width: 40px;
    text-align: center;
    border: none;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding: 5px 0;
  }
  
  .quantity-control input::-webkit-outer-spin-button,
  .quantity-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .cart-actions {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }
  
  .return-shop, .update-cart {
    padding: 10px 20px;
    border: 1px solid #000;
    background: #fff;
    cursor: pointer;
  }
  
  .coupon-section {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .coupon-section input {
    flex: 1;
    padding: 10px;
    border: 1px solid #e5e5e5;
  }
  
  .apply-coupon {
    padding: 10px 20px;
    background: #000;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .cart-total {
    width: 300px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    height: fit-content;
  }
  
  .cart-total h2 {
    margin: 0 0 20px 0;
  }
  
  .total-row {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .total-row.total {
    font-weight: bold;
  }
  
  .checkout-button {
    width: 100%;
    padding: 15px;
    background: #000;
    color: #fff;
    border: none;
    margin-top: 20px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .cart-content {
      flex-direction: column;
    }
  
    .cart-total {
      width: 100%;
    }
  
    .cart-table-header {
      display: none;
    }
  
  
    .item-product {
      grid-column: 1 / -1;
    }
  
    .item-price, .item-size, .item-color, .item-quantity, .item-subtotal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
    }
  
    .item-price::before {
      content: 'Price:';
      font-weight: 500;
    }
  
    .item-size::before {
      content: 'Size:';
      font-weight: 500;
    }
  
    .item-color::before {
      content: 'Color:';
      font-weight: 500;
    }
  
    .item-quantity::before {
      content: 'Quantity:';
      font-weight: 500;
    }
  
    .item-subtotal::before {
      content: 'Subtotal:';
      font-weight: 500;
    }
  
    .item-action {
      position: absolute;
      top: 20px;
      right: 0;
    }
  
    .cart-actions {
      flex-direction: column;
      gap: 10px;
    }
  
    .coupon-section {
      flex-direction: column;
    }
  
    .apply-coupon {
      width: 100%;
    }
  
    .price-details {
      text-align: right;
    }
  }
  .delete-button {
    background: none;
    border: none;
    color: #ff0000;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease;
  }
  
  .delete-button:hover {
    color: #cc0000;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    width: fit-content;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .qty-btn {
    padding: 8px 12px;
    border: none;
    background: #f8f9fa;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .qty-btn:hover {
    background: #e9ecef;
  }
  
  .quantity-control input {
    width: 50px;
    text-align: center;
    border: none;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding: 8px 0;
    font-size: 14px;
  }
  
  .quantity-control input::-webkit-outer-spin-button,
  .quantity-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  