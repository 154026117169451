/* Container and layout */
.checkout-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.checkout-breadcrumb {
  margin-bottom: 30px;
  color: #666;
}

.checkout-breadcrumb span {
  margin: 0 5px;
}

.checkout-breadcrumb .active {
  color: #000;
  font-weight: 500;
}

.checkout-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

/* Billing Form Styles */
.billing-form h2 {
  font-size: 24px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.form-group input.error {
  border-color: #ff4444;
}

.form-group input:focus {
  outline: none;
  border-color: #000;
  background-color: #fff;
}

/* Saved Addresses Styles */
.saved-addresses {
  margin-bottom: 2rem;
}

.address-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
}

.address-card:hover {
  border-color: #000;
}

.address-card.selected {
  border-color: #000;
  background-color: #f8f8f8;
}

.address-details p {
  margin: 0.25rem 0;
  color: #666;
}

.address-details .name {
  font-weight: 500;
  color: #000;
}

.new-address-btn {
  background-color: #fff;
  border: 1px solid #000;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.new-address-btn:hover {
  background-color: #000;
  color: #fff;
}

.back-to-addresses {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
}

.back-to-addresses:hover {
  background-color: #e9ecef;
}

/* Save Info Checkbox */
.save-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.save-info input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

/* Order Summary Styles */
.order-summary {
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 8px;
  height: fit-content;
}

.cart-items {
  margin-bottom: 30px;
}

.cart-item {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.cart-item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.item-details h3 {
  margin: 0;
  font-size: 16px;
}

.order-totals > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.total {
  font-size: 18px;
  font-weight: 600;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

/* Payment Methods */
.payment-methods {
  margin: 30px 0;
}

.payment-option {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

/* Coupon Section */
.coupon-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.coupon-section input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.apply-coupon {
  padding: 12px 24px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Place Order Button */
.place-order {
  width: 100%;
  padding: 15px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.place-order:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.place-order:hover:not(:disabled),
.apply-coupon:hover {
  background-color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .checkout-content {
    grid-template-columns: 1fr;
  }
  
  .order-summary {
    margin-top: 30px;
  }
  
  .coupon-section {
    flex-direction: column;
  }
  
  .apply-coupon {
    width: 100%;
  }
}