.orders-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .orders-table th, .orders-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .orders-table tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .status {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .status.shipped {
    color: white;
    background-color: green;
  }
  
  .status.processing {
    color: white;
    background-color: orange;
  }
  
  .order-details {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: white;
  }
  
  /* .order-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
  }
   */
  .order-item {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .order-item img {
    width: 60px;
    height: 60px;
    border-radius: 5px;
  }
  
  .back-btn {
    background: none;
    border: none;
    color: blue;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .back-btn:hover {
    text-decoration: underline;
  }
  