:root {
  --primary: #f2bd0f;
  --secondary: #6c757d;
}

.flash-sales {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.today-label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.separator {
  font-size: 15px;
  color: var(--primary); /* Use primary color */
  margin-right: 3px;
  font-weight: 900;
}

.today-label {
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  color: var(--secondary); /* Use secondary color */
}

.flash-sales-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

h2 {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
}

.timer {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.time-box {
  background: var(--primary); /* Use primary color */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.image-wrapper {
  position: relative;
}

.discount {
  position: absolute;
  top: 10px;
  left: 10px;
  background: var(--primary); /* Use primary color */
  color: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 3px;
}

.wishlist-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}



.add-to-cart {
  background: var(--secondary); /* Use primary color */
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.view-all {
  background: var(--primary); /* Use primary color */
  color: white;
  border: none;
  padding: 12px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.scroll-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.scroll-btn {
  background: var(--primary); /* Use primary color */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .separator {
    font-size: 28px;
  }

  .today-label {
    font-size: 18px;
  }

  .flash-sales-info {
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-size: 24px;
  }

  .timer {
    flex-wrap: wrap;
    font-size: 16px;
  }

  .product-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .scroll-buttons {
    display: none;
  }
}
