:root {
    --primary: #f2bd0f;
    --secondary: #6c757d;
  }
.wishlist-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .wishlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .move-to-bag {
    padding: 10px 20px;
    border: 1px solid #000;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .move-to-bag:hover {
    background: #000;
    color: #fff;
  }
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .product-card {
    position: relative;
    border: 1px solid #e5e5e5;
    padding: 10px;
    transition: all 0.3s ease;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .product-image {
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;
  }
  
  .product-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .discount {
    position: absolute;
    top: 10px;
    left: 10px;
    background: var(--primary);
    color: #fff;
    padding: 4px 8px;
    z-index: 1;
  }
  
  .new-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #00ff66;
    color: #000;
    padding: 4px 8px;
    z-index: 1;
  }
  
  .remove-button,
  .wishlist-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .add-to-cart {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: var(--secondary);
    color: #fff;
    border: none;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .product-card:hover .add-to-cart {
    opacity: 1;
  }
  
  .product-info {
    padding: 10px 0;
  }
  
  .product-info h3 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .price-info {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .current-price {
    font-weight: bold;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #666;
  }
  
  .rating {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 8px;
  }
  
  .star-filled {
    fill: gold;
    stroke: gold;
  }
  
  .star-empty {
    fill: none;
    stroke: #ccc;
  }
  
  .reviews {
    color: #666;
    font-size: 14px;
  }
  
  .recommended-section {
    margin-top: 40px;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .see-all {
    padding: 8px 16px;
    border: 1px solid #000;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .see-all:hover {
    background: #000;
    color: #fff;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .products-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .products-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .products-grid {
      grid-template-columns: 1fr;
    }
    
    .wishlist-header {
      flex-direction: column;
      gap: 15px;
    }
    
    .section-header {
      flex-direction: column;
      gap: 15px;
      text-align: center;
    }
  }