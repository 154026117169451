:root {
  --primary: #f2bd0f;
  --secondary: #6c757d;
  --text: #000;
  --bg-light: #f8f9fa;
  --white: #fff;
}

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden; /* Prevents horizontal scrolling */
  font-family: Arial, sans-serif;
}

/* Main Container */
.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
  max-width: 1400px;  /* Centers content on larger screens */
  margin: 0 auto;
}

.hero-left {
  flex: 1 1 25%;  /* Flex: grow, shrink, basis */
  padding-right: 20px;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.hero-center {
  display: flex;
  flex: 1 1 75%;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
  flex-wrap: wrap;
}

/* Side Menu Styles */
.menu-list {
  width: 220px;
  background-color: var(--bg-light);
  border-radius: 8px;
}

.menu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  font-size: 1rem;
  padding: 12px 15px;
  border-radius: 6px;
  margin-bottom: 6px;
  cursor: pointer;
  background: var(--white);
  transition: all 0.3s ease;
}



/* Divider */
.border-divider {
  width: 2px;
  height: 100%;
  background-color: var(--primary);
  margin: 0 20px;
}

.carousel-container {
  width: 100%;
  max-width: 1074px; /* Adjust to prevent full-width issue */
  overflow: hidden;
  position: relative;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Fix Slick styles */
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: white;
}

.slick-dots li.slick-active button:before {
  color: #f2bd0f; /* Your primary color */
}

.carousel-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

/* Mobile View (Responsive) */
@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    padding: 10px;
    max-width: 100%;
    margin: 0;
  }

  .hero-left, .hero-center, .carousel-container {
    width: 100%;
    flex: unset;
  }

  .menu-list {
    width: 100%;
    margin-bottom: 20px;
  }

  .border-divider {
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }

  .menu-list li {
    font-size: 0.9rem;
  }

  .carousel-text {
    font-size: 1rem;
  }
}

/* Large Screen (Centered content with space) */
@media (min-width: 1400px) {
  .hero-container {
    max-width: 1400px;
    margin: 0 auto;
  }
}
