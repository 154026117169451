.order-container-d {
    width: 80%;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  
  .order-header h2 {
    font-size: 22px;
    color: #333;
  }
  
  .status {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .status.shipped {
    background: #ff9800;
    color: #fff;
  }
  
  .status.delivered {
    background: #4caf50;
    color: #fff;
  }
  
  .status.pending {
    background: #f44336;
    color: #fff;
  }
  
  .order-info, .order-summary {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  h3 {
    font-size: 18px;
    color: #444;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
  }
  
  .order-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .order-item-card {
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: calc(50% - 10px);
    align-items: center;
  }
  
  .product-image-o {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 15px;
  }
  
  .item-details h4 {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }
  