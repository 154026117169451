:root {
    --primary-color: #f2bd0f;
    --secondary-color: #6c757d;
    --bg-color: #f8f9fa;
}

/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-family: Arial, sans-serif;
}

/* Main Product Section */
.products-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}

/* Left-side Filters */
.filters-container {
    width: 20%;
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: hidden; /* Hide scrollbar in filter section */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.filters-container h3 {
    font-size: 1.2rem;
    color: var(--primary-color);
}

.filters-container .filter-group {
    margin-bottom: 20px;
}

.filters-container h4 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.filters-container ul {
    list-style-type: none;
    padding: 0;
}

.filters-container li {
    margin-bottom: 10px;
    font-size: 1rem;
}

.filters-container input[type="checkbox"] {
    margin-right: 10px;
}

.filters-container .price-range {
    width: 100%;
}

/* Right-side Product List */
.products-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    flex: 1;
    overflow-y: scroll; /* Enable vertical scrolling for products */
    max-height: 100vh; /* Limit height to fit within the screen */
    padding-right: 20px; /* To prevent scrollbar overflow */
    margin-bottom: 20px; /* Space for last row */
}

/* Hide scrollbar but keep scroll functionality */
.products-list-container::-webkit-scrollbar {
    width: 0;
    height: 0;
}


/* If there is only one row of products, ensure the card height is flexible */
.products-list-container:only-child .product-card {
    height: auto;
}



/* Responsive Design */
@media (max-width: 1024px) {
    .products-section {
        flex-direction: column;
        padding: 10px;
    }

    .filters-container {
        width: 100%;
        margin-bottom: 20px;
    }

    .products-list-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
    }
}

@media (max-width: 768px) {
    .filters-container {
        width: 100%;
    }

    .products-list-container {
        grid-template-columns: 1fr;
    }
}
